<template>
    <div>
        <h2>{{ $t("payment.payment") }}</h2>
        <div class="box">
            <CustomTable
                ref="table_tiers_payment"
                id_table="tiers_payment"
                :items="payments"
                :busy.sync="table_busy_payments"
                primaryKey="payment_id"
                :hide_if_empty="true"
            	:externSlotColumns="extern_slot_columns"
            >
				<template v-slot:custom-slot-cell(check_deposit_formatted)="{ data }">
					<div class="d-flex align-items-center">
						<a href="#" @click.prevent="print({checkdeposit_ids: data.check_deposit.checkdeposit_id.toString()})">{{ data.check_deposit_formatted }}</a>
					</div>
				</template>
				<template v-slot:custom-slot-cell(formatted_invoices)="{ data }">
					<p v-for="invoice in data.invoices" :key="invoice.invoice_id">
                        <a href="" class="" @click.prevent="quickPreview(invoice.invoice_id)">{{invoice.invoice_num}}</a> ({{ $t('invoice.invoice_balance') }} : {{ invoice.invoice_balance }})
                    </p>
				</template>
                <template v-slot:custom-slot-cell(contracts)="{ data }">
                    <span v-for="(contract, idx) in data.contracts" class="d-flex align-items-center" :key="data.invoice_id+'_'+idx+'_'+contract.contract_id">
                        <router-link :to="{ name: 'ContractFormv2', params: { contract_id: contract.contract_id }}">
                            {{ contract.contract_num }}
                        </router-link>
                    </span>
                </template>
			</CustomTable>
        </div>

        <h2>{{ $t("payment.compte_courant") }}</h2>
        <div class="box">
            <p
                class="mb-4"
                v-for="current_account in current_accounts"
                :key="current_account.key"
            >
                {{ $t('payment.current_account_tiers') }} "{{ current_account.key }}" : {{ current_account.amount }}
            </p>

            <CustomTable
                id_table="tiers_payment_current_account"
                :items="payments_accounts"
                :busy.sync="table_busy_current"
                primaryKey="currentaccount_id"
                :hide_if_empty="true"
            />
        </div>


        <b-modal ref="modalPayment" hide-footer size="xl">
            <template v-slot:modal-title>
                    {{ $t("payment.add_payment") }}
            </template>

            <AddPayment ref="addPayment" :invoice_ids="invoice_ids" :invoice_nums="invoice_nums" :tiers_id="tiers_local_id" :processing.sync="processing" :ready.sync="ready" :submit_payment.sync="submit_payment"></AddPayment>

            <div class="d-none col-8 m-auto">
                <b-button v-if="ready" block pill variant="primary" @click.prevent="onSubmit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
            </div>
        </b-modal>

        <b-modal ref="modalEditPayment" hide-footer>
            <template v-slot:modal-title>
                    {{ $t("payment.edit_payment") }}
            </template>

            <EditPayment ref="editPayment" :payment="payment" :processing.sync="processing" :ready.sync="ready"></EditPayment>

            <div class="col-8 m-auto">
                <b-button v-if="ready" block pill variant="primary" @click.prevent="onSubmitEdit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.modifier") }}</b-button>
            </div>
        </b-modal>

        <b-modal size="xl" ref="modelPreview" hide-footer @hidden="base64 = null">
            <template v-slot:modal-title>
                {{ $t("action.previsualiser") }}
            </template>

            <iframe v-if="base64 != null" :src="'data:application/pdf;base64,' + base64" height="1000px" width="100%" style="position:relative"></iframe>
            <div v-else>
                <LoadingSpinner />
            </div>
        </b-modal>

        <ModalConfirm
            ref="confirm_unpaid_payment"
            id="confirm_unpaid_payment_c"
            :icon="['far', 'check']"
            :icon_accept="['fal', 'level-down']"
            :text_title="$t('modal.invoice.validation_unpaid_payment')"
            :text_question="$t('modal.invoice.text_validation_unpaid_payment')"
            :text_button_ok="$t('modal.general.ok')"
            :text_button_cancel="$t('modal.general.annuler')"
            :callback_validation="checkUnpaidPayment"
            :callback_cancel="cancelSwitchUnpaidPaymentModal"
            :processing="processing_unpaid_payment"
        />
        
        <ModalConfirm
            ref="confirm_cancel_payment"
            id="confirm_cancel_payment_c"
            :icon="['far', 'check']"
            :icon_accept="['fal', 'level-down']"
            :text_title="$t('modal.invoice.validation_cancel_payment')"
            :text_question="$t('modal.invoice.text_validation_cancel_payment')"
            :text_button_ok="$t('modal.general.ok')"
            :text_button_cancel="$t('modal.general.annuler')"
            :callback_validation="checkCancelPayment"
            :callback_cancel="cancelSwitchCancelPaymentModal"
            :processing="processing_cancel_payment"
        />

    </div>
</template>


<script type="text/javascript">
    import Vue from 'vue'
    import { EventBus } from 'EventBus'
    import CustomTable from "GroomyRoot/components/Table/CustomTable"
    import TableAction from "@/mixins/TableAction.js"
    import Invoice from "@/mixins/Invoice.js"
    import Navigation from "@/mixins/Navigation.js"
    import Payment from "@/mixins/Payment.js"
    import Config from "@/mixins/Config.js"
    import Tiers from "@/mixins/Tiers.js"

    import AddPayment from "@/components/Payment/AddPayment"

    export default {
        name: "listPayment",
        mixins: [Config, TableAction, Invoice, Navigation, Payment, Tiers],
        props: ['tiers_id'],
        data () {
            return {
				tiers_local_id: 0,
                payment: null,
                payments: null,
                payments_accounts: [],
                base64: null,
                table_busy_payments: true,
                table_busy_current: true,
                modal_loaded: false,
                ready: false,
                processing: false,
                current_accounts: [],
                invoice_ids: [],
                invoice_nums: [],
                processing_unpaid_payment: false,
                processing_cancel_payment: false,
                unpaid_params: null,
                cancel_params: null,
				extern_slot_columns: ['check_deposit_formatted', 'formatted_invoices', 'contracts'],
                events_tab: {
                    'tiersPayment::reloadTabs': this.loadPayments,
                    'TableAction::goToAddPayment': this.check_display_payment_form,
                    'TableAction::goToUnpaidPayment': this.confirmCheckUnpaidPayment,
                    'TableAction::goToCancelPayment':  this.confirmCheckCancelPayment,
                    'TableAction::goToEditPayment': this.edit_payment,
                },
                submit_payment: false
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
				this.tiers_local_id = await this.replaceOrSynchronizeId(this.tiers_id)

                this.loadCurrentAccount()
                this.loadPayments()
            },
            async loadPayments() {
                const payments = await this.listTiersPayment(this.tiers_local_id)
                this.payments = await this.formatPayments(payments)

                this.table_busy_payments = false
            },

            async loadCurrentAccount() {
                let tiers = await this.loadTier(this.tiers_local_id)

                // si mon id est encore négatif je ne peux pas appeler l'API
                if(this.tiers_local_id < 0) {
                    this.table_busy_current = false
                    return false
                }

                let current_account = await this.getCurrentAccount(this.tiers_local_id)
                this.current_accounts = []

                // for (let ca in current_account)
                // {
                //     this.current_accounts.push({
                //         amount: await this.priceFormat(current_account[ca].currentaccount_balance / 100, tiers.tiers_currency, true),
                //         key: current_account[ca].accounting_plan.accountingplan_label
                //     })
                // }


				let current_account_details = await this.getCurrentAccountDetails(this.tiers_local_id)
				this.payments_accounts = []

                for (let cad in current_account_details)
                {
                    this.current_accounts.push({
                        amount: await this.priceFormat(current_account_details[cad][0].currentaccount_balance / 100, tiers.tiers_currency, true),
                        key: cad
                    })

                    for (let lignes in current_account_details[cad])
                    {
                        this.payments_accounts.push({
                            currentaccount_id: current_account_details[cad][lignes].currentaccount_id,
                            currentaccount_date: current_account_details[cad][lignes].currentaccount_date,
                            currentaccount_inserted_date: current_account_details[cad][lignes].currentaccount_inserted_date,
                            currentaccount_amount: await this.priceFormat(current_account_details[cad][lignes].currentaccount_amount / 100) ,
                            currentaccount_balance: await this.priceFormat(current_account_details[cad][lignes].currentaccount_balance / 100) ,
                            accountingplan_label: cad,
                        })
                    }
                }
                
                this.table_busy_current = false
            },

            checkPaymentFormFunction(params){
                this.$refs['modalPayment'].show()
            },

            edit_payment(params) {
                this.payment = params
                this.payment.payment_date = new Date(this.payment.payment_date)
                this.$refs['modalEditPayment'].show()
            },

            async onSubmitEdit() {
                const done = await this.$refs['editPayment'].editPayment()
                if(done) {
                    this.$refs["modalEditPayment"].hide()
                    this.loadPayments()
                }
            },

            async onSubmit() {
                if(!this.processing)
                {
                    this.processing = true
                    let return_payment = await this.$refs['addPayment'].addPayment()
                    if(return_payment)
                    {
                        this.$refs["modalPayment"].hide()
                        this.$store.commit({ type: 'updateRefreshTabs', view: 'TiersFiche', tab: "coordonneesTiers", val: true })
                        this.$store.commit({ type: 'updateRefreshTabs', view: 'TiersFiche', tab: "Invoice", val: true })
                        this.table_busy = true
                        await this.init_component()
                    }
                    this.processing = false
                }
            },

            confirmCheckUnpaidPayment(unpaid_params) {
                this.unpaid_params = unpaid_params
                return this.$refs.confirm_unpaid_payment.openModal()
            },

            confirmCheckCancelPayment(cancel_params) {
                this.cancel_params = cancel_params
                return this.$refs.confirm_cancel_payment.openModal()
            },

            cancelSwitchUnpaidPaymentModal() {
				this.$refs.confirm_unpaid_payment.closeModal()
			},

            cancelSwitchCancelPaymentModal() {
				this.$refs.confirm_cancel_payment.closeModal()
			},
            
            async checkUnpaidPayment(){
                this.processing_unpaid_payment = true
                await this.check_unpaid_payment(this.unpaid_params)
                this.processing_unpaid_payment = false
                this.$refs.confirm_unpaid_payment.closeModal()
                this.table_busy_payments = true
                await this.init_component()
            },

            async checkCancelPayment(){
                this.processing_cancel_payment = true
                const res = await this.check_cancel_payment(this.cancel_params)
                this.processing_cancel_payment = false
                this.$refs.confirm_cancel_payment.closeModal()

                // Si pas de retour, c'est qu'il y a une erreur, pas besoin de refresh la table
                if (!res || !res.retour) return

                this.table_busy_payments = true
                await this.init_component()
            },

			print(params) {
				this.printCheckDeposit(params)
			},

            async quickPreview(invoice_id) {
                this.$refs.modelPreview.show()
                let retour = await this.pdfInvoice([invoice_id], "", true)
                if(retour) {
                    this.base64 = retour
                }
            },

            unselectAll() {
        		this.$refs.table_tiers_payment.unselectAll()
        	}
        },
        watch: {
            submit_payment() {
                this.onSubmit()
            }
        },
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            EditPayment: () => import('@/components/Payment/EditPayment'),
            ModalConfirm: () => import('@/components/Modals/ModalConfirm'),

            CustomTable,
            AddPayment
        }
    }
</script>
